.ubf-cta-button {
	display: flex;
	flex-direction: row;
	&__link {
		//exception to win over faculty styles and keep it simple
		border-color: $colorHighlightBase!important;
		background-color: $colorHighlightBase!important;
	}
	& &__link.ubf-button--secondary {
		color: $colorBase!important;
		background-color: rgba(0,0,0,0)!important;
	}

	&__link {
		max-width: 32rem;
		overflow: hidden;
	}
	&--right {
		justify-content: flex-end;
	}
	&--center {
		justify-content: center;
	}
	&--fullwidth {
		width:100%;
		max-width: 100%!important;
	}
}
